import axios from "axios";
import API_URL from "../../config/API_URL";

const DEV_API_URL = API_URL.includes("dev.") ? API_URL : API_URL.replace("https://", "https://dev.").replace("http://", "http://dev.");

const ssApiService = axios.create({
    baseURL: API_URL,
    withCredentials: false,
});

export default ssApiService;