import { LogWithPrefix } from './Log';

export const MY_CREATION_CACHE_KEY = 'my-creations';
export const PROFILE_CACHE_KEY_BY_UID = (uid) => `user-profile/${uid}`;
export const CREATION_THUMBNAIL_CACHE_KEY_BY_UID_AND_CREATION_ID = (uid, creationId) => `/UserProject/${uid}/${creationId}`;
export const META_CACHE_KEY_BY_UID = 'user-profile/meta/detail';
export const CHECK_USER = 'check_user';

const Log = new LogWithPrefix('XX');

export const checkIsRevalidate = (url) => {
  if (typeof window !== 'undefined') {
    const isRevalidate = window.revalidates && window.revalidates.includes(url);
    if (isRevalidate) {
      window.revalidates = window.revalidates.filter((revalidate) => revalidate !== url);
      Log.info('isRevalidate', url);
    }
    return isRevalidate;
  }
  return true;
};

export const clearCacheByKeys = async (...keys) => {
  Log.info('clearCacheByKeys', keys);
  if (typeof caches === 'undefined') return;
  caches.keys()
    .then(async (cacheNames) => {
      await Promise.all(
        cacheNames.map(async (cacheName) => {
          await caches.open(cacheName).then(
            async (cache) => {
              const cacheKeys = await cache.keys();
              const cacheKeysFiltered = cacheKeys.filter((cacheKey) => {
                const { url } = cacheKey;
                const isMatched = keys.some((key) => url.includes(key));
                return isMatched;
              });
              cacheKeysFiltered.map(async (cacheKey) => {
                await cache.delete(cacheKey);
                if (typeof window !== 'undefined') {
                  if (window.revalidates) {
                    window.revalidates.push(cacheKey.url);
                  } else {
                    window.revalidates = [cacheKey.url];
                  }
                }
              });
            },
          );
        }),
      );
    });
};

export const deleteAllCaches = () => {
  caches.keys().then((keyList) => Promise.all(
    keyList.map((key) => caches.delete(key)),
  ));
};
