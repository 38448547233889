import apiService from "./apiService"

const AuthenticationServices = () => {
    const generateToken = async () => {
        const { data: { key } } = await apiService.get('/auth/generated-token');
        return key;
    }

    const checkUser = async () => {
        const { data } = await apiService.get('/user-profile/check_user/');
        return data[0] || null;
    }

    return {
      generateToken,
      checkUser
    }
}

export default AuthenticationServices