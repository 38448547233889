import {
  getAuth,
  getIdToken,
} from 'firebase/auth';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../hooks/useAuth';
import { authState, setToken } from '../store/reducers/authReducer';
import { appState } from '../store/reducers/appReducer';
import { setVerifyEmailPopup } from '../store/reducers/notificationReducer';

const onAuthStateChange = () => {
  const auth = getAuth();
  const dispatch = useDispatch();

  const { setUserData, logout, fetchUserData } = useAuth();
  const { userData, user } = useSelector(authState);
  const { listAppByKeyword } = useSelector(appState);

  const excludeList = listAppByKeyword?.verification_exclude_list
    ? listAppByKeyword.verification_exclude_list?.value?.list : [];

  useEffect(() => {
    // buat solve ketika logout dari new tab, close new tab, reload current tab, data user masih ada

    const listener = auth.onAuthStateChanged((usr) => {
      /*
        harus verified :
        - emailVerified = false
        - tidak masuk exclude
        - bukan @assemblrworld.com
        - firebase provider = password
       */

      if (usr && excludeList.length > 0) {
        if (!usr.emailVerified
          && !(excludeList.includes(usr.email) || usr.email.includes('@assemblrworld.com'))
          && usr.providerData[0].providerId === 'password') {
          dispatch(setVerifyEmailPopup(true));
        }
        if (usr.emailVerified) {
          dispatch(setVerifyEmailPopup(false));
        }
      }

      if (usr?.uid === userData?.FirebaseID && (usr?.accessToken !== userData?.accessToken)) {
        setUserData({ accessToken: usr?.accessToken }, 'onauthstatechane');
      }
    });

    return () => {
      listener();
    };
  }, [excludeList.length]);

  const onFocus = useCallback(() => setTimeout(async () => {
    if (auth?.currentUser && userData && user) {
      try {
        const _token = await getIdToken(auth.currentUser);
        dispatch(setToken(_token));
      } catch { }
      return;
    }

    if (auth?.currentUser?.uid && userData === null) {
      const data = await fetchUserData(auth?.currentUser?.uid);
      setUserData({ ...data });
    } else if (!auth?.currentUser && userData === null && user === null && !localStorage.getItem('_token')) {
      logout(true);
    }
  }, 500), [auth?.currentUser?.uid, userData]);

  useEffect(() => {
    window.addEventListener('visibilitychange', onFocus);
    return () => {
      window.removeEventListener('visibilitychange', onFocus);
    };
  }, [auth, userData, user]);

  return null;
};

export default onAuthStateChange;
