const NewRelic = {
  script: `https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/scripts/newrelic/${
    process.env.NODE_ENV === 'production' ? 'studio' : 'dev'
  }.js`,
  setUserId: (uid) => {
    if (typeof window !== 'undefined' && window.newrelic && typeof window.newrelic.setUserId === 'function') {
      if (window.newrelic.userId !== uid) {
        console.log('Set user id to new relic', uid);
        window.newrelic.setUserId(uid);
        window.newrelic.userId = uid;
      }
    }
  },
};

export default NewRelic;
