import { initializeApp } from 'firebase/app'

export const firebaseConfig = {
    databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_ID,
    authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_KEY,
    storageBucket: process.env.NEXT_PUBLIC_STORAGEBUCKET,
    measurementId: process.env.NEXT_PUBLIC_MEASUREID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
    messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
}

// for fix frame motion implementation
let app

try {
    if (!app) {
        app = initializeApp(firebaseConfig)
    }
} catch (error) {
    console.log(error)
}

export { app };