/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable camelcase */
/* eslint-disable no-return-await */
import {
  createUserWithEmailAndPassword, getAuth, getIdToken, sendEmailVerification, signOut,
} from 'firebase/auth';
import {
  useEffect, useState,
} from 'react';
import nookies from 'nookies';
import {
  child, get, getDatabase, ref, set,
} from 'firebase/database';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { useRouter } from 'next/router';
import { DevicesLoginLimitation } from 'assemblr-ui';
import {
  asyncCheckUser,
  authState, clearUser, setToken, setUser, setUserData as setUserDataAction,
} from '../store/reducers/authReducer';
import { deleteAllCaches } from '../utils/cacheRoutes';
import { resetNotification, setVerifyEmailSent } from '../store/reducers/notificationReducer';
import { setUserDetail } from '../store/actions/userActions';
import useUserAgent from './useUserAgent';
import NewRelic from '../config/NewRelic';
import { resetMyCreations } from '../store/reducers/creationReducer';
import UserServices from '../services/UserServices';
import { setCurrency } from '../store/reducers/appReducer';
import { resetLibraryState } from '../store/reducers/libraryReducer';
import Versioning, { versionByEmail } from '../utils/Versioning';
import { currentDistinctId } from './useAnalytics';
import apiService from '../services/apiService';

export const actionCodeSettings = {
  url: `${process.env.NEXT_PUBLIC_WEB_URL}/finish-verified`,
  handleCodeInApp: true,
};

const brodcastChannel = new BroadcastChannel('auth');

const useAuth = (main = false) => {
  const {
    user,
    // solve delay, TODO : change
    userData,
    userMeta, loadingUserMeta, checkUser, loadingCheckUser, token,
  } = useSelector(authState);

  const loggingOut = typeof window !== 'undefined' && localStorage.getItem('loggingOut');
  const setLoggingOut = (val) => {
    if (typeof window === 'undefined') return;
    if (val) {
      localStorage.setItem('loggingOut', 'true');
    } else {
      localStorage.removeItem('loggingOut');
    }
  };

  const dispatch = useDispatch();
  const db = getDatabase();
  const auth = getAuth();
  const { isMobile } = useUserAgent();
  const [loading] = useState(true);
  const { replace, pathname } = useRouter();

  const setupUserData = async (param) => {
    const tkn = await getIdToken(param);
    dispatch(setToken(tkn));
    nookies.set(undefined, 'token', tkn, { path: '/' });
    nookies.set(undefined, 'uid', param.uid, { path: '/' });
    dispatch(asyncCheckUser());
  };

  const setUserData = (data, source) => {
    if (loggingOut) return;

    const newData = (data) ? { ...userData, ...data } : null;
    const currUser = data ? auth.currentUser : null;

    Versioning.set(newData?.version || 1);
    dispatch(setUserDataAction(newData));
    dispatch(setUserDetail(newData));
    dispatch(setUser(currUser));
    if (data) setupUserData(currUser);
  };

  const fetchUserData = async (uid) => {
    try {
      const { data } = await apiService.get(`/user-profile/${uid}`);
      return data;
    } catch (err) {
      return null;
    }
  };

  const updateUserData = async (newData) => {
    await set(ref(db, `/UserProfile/${user?.uid}`), { ...userData, ...newData });
    setUserData({ ...userData, ...newData });
  };

  const register = async ({
    name, username, email, country,
  }, credUser) => {
    if (credUser.uid !== null && credUser.uid) {
      const checkUserExist = await fetchUserData(credUser.uid);
      const Source = (isMobile.any()) ? 4 : 3;

      if (!checkUserExist && email.toLowerCase() === credUser.email.toLowerCase()) {
        sendEmailVerification(credUser, actionCodeSettings)
          .catch((err) => console.log({ err }));

        console.log({ credUser });

        const uData = {
          Name: name,
          Username: username,
          FirebaseID: credUser.uid,
          Email: email,
          Country: country,
          Bio: '',
          Birthdate: '',
          Coin: 0,
          Diamond: 0,
          Source,
          AgreementPrivacyPolicy: true,
          ProfilePictureURL: 'https://asset.asblr.app/Asset/placeholder/defaultProfilePicture.jpeg',
          accessToken: credUser.accessToken,
          version: versionByEmail(email, credUser.metadata.createdAt),
        };
        // insert User to Database
        setUserData(uData);
        set(ref(db, `/UserProfile/${credUser.uid}`), uData);
        // insert UserID to Database
        set(ref(db, `/UsernameData/${username}`), credUser.uid);
        window.dataLayer.push({
          event: 'sign_up',
          data: {
            method: 'email',
            uuid: !currentDistinctId()?.includes?.('$device') ? currentDistinctId() : uData.FirebaseID,
          },
        });

        mixpanel.track('sign_up', {
          method: 'email',
          uuid: !currentDistinctId()?.includes?.('$device') ? currentDistinctId() : uData.FirebaseID,
        });

        window.dataLayer.push({
          event: 'auth_validated',
          data: {
            uuid: uData.FirebaseID,
            user_avatar: uData.ProfilePictureURL,
            user_name: uData.Username,
            user_display_name: uData.Name,
          },
        });
        localStorage.setItem('already_validated', 'true');
        return uData;
      }
    }
  };

  const checkAvailable = async ({
    name, username, email, password, country,
  }) => await new Promise(async (resolve) => {
    createUserWithEmailAndPassword(auth, email, password).then(async (res) => {
      const uData = await register({
        name, username, email, country,
      }, res.user);
      resolve(uData);
    }).catch(async (err) => {
      let error_code = '';
      console.log({ err });
      switch (err.code) {
        case 'auth/email-already-in-use':
          error_code = 'Email Already In Use';
          break;

        default:
          break;
      }
      resolve(error_code || err);
    });
  });

  const forceLogout = (tempUID, withBroadcast) => {
    try {

      if (userData) {
        window.dataLayer.push({
          event: 'logout',
          data: {
            uuid: tempUID,
            user_avatar: userData.ProfilePictureURL,
            user_name: userData.Username,
            user_display_name: userData.Name,
          },
        });

        mixpanel.track('logout', {
          uuid: tempUID,
          user_avatar: userData.ProfilePictureURL,
          user_name: userData.Username,
          user_display_name: userData.Name,
        });
        if (!currentDistinctId()?.includes?.('$device')) {
          mixpanel.reset();
        }
      }

      signOut(auth);

      dispatch(setToken(null));
      dispatch(resetNotification());
      dispatch(clearUser());
      dispatch(resetLibraryState());
      dispatch(resetMyCreations());
      setUserData(null);
      localStorage.clear();
      nookies.destroy(undefined, 'token', { path: '/' });
      nookies.destroy(undefined, 'uid', { path: '/' });
      deleteAllCaches();

      if (withBroadcast) {
        try {
          brodcastChannel?.postMessage('astu_logout');
        } catch (err) {
          console.log(err);
        }
      }

      setTimeout(() => {
        setLoggingOut(false);
      }, 1000);

      const redirestPath = ['projects', 'markers', 'creator', 'account'];
      if (redirestPath.some((path) => pathname.includes(path))) {
        replace('/explore', null, { scroll: false }).then(() => {
          if (isMobile.Assemblr()) {
            // refresh current page delay 1 second
            window.location = `assemblr://logout?userid=${tempUID}`;
          }
        });
      }
    } catch (err) {
      console.log({ logout: err });
    }
  };

  const logout = (withBroadcast = true) => {
    setLoggingOut(true);
    const tempUID = userData?.FirebaseID || user?.uid;
    if (userData) {
      DevicesLoginLimitation.getCurrentDevice()
        .then(async (device) => {
          await DevicesLoginLimitation.removeDevice(device.deviceId, false);
          forceLogout(tempUID, withBroadcast);
        })
        .catch((err) => {
          console.log(err);
          forceLogout(tempUID, withBroadcast);
        });
      forceLogout(tempUID, withBroadcast);
    } else {
      forceLogout(tempUID, withBroadcast);
    }
  };

  const fetchCurrency = async () => {
    const currency = await UserServices.getCurrency(user.uid, userMeta);
    dispatch(setCurrency(currency));
  };

  const handleVerify = () => {
    sendEmailVerification(auth.currentUser, actionCodeSettings)
      .then(() => dispatch(setVerifyEmailSent()))
      .catch((err) => {
        if (err?.code === 'auth/too-many-requests') {
          alert('Oops! too frequent send verification or email has been sent. Kindly check your email.');
        } else {
          alert('Something wrong. Please try again later.');
        }
        console.log({ err });
      });
  };

  const handleListener = (evt) => {
    if (evt.data === 'send-email-verification') {
      handleVerify();
    }
  };

  const checkAuth = () => {
    if (!loading && !user) {
      throw new Error('You are not authenticated');
    } else {
      return true;
    }
  };

  const renewToken = async () => {
    const newToken = await auth.currentUser.getIdToken(true);
    setToken(newToken);
    return newToken;
  };

  useEffect(() => {
    if (!main) return;
    if (user && user !== window?.lastUser) {
      window.lastUser = user;
      NewRelic.setUserId(user.uid);
    }
    if (user) {
      fetchCurrency();
    }
  }, [user]);

  useEffect(() => {
    if (!main) return;
    window.addEventListener('message', handleListener);

    return () => {
      window.removeEventListener('message', handleListener);
    };
  }, [auth?.currentUser]);

  useEffect(() => {
    if (!main) return;
    brodcastChannel.onmessage = (evt) => {
      if (evt.data === 'astu_logout') {
        logout(false);
      }
    };
    return () => {
      setTimeout(() => {
        brodcastChannel.close();
      }, 1000);
    };
  }, []);

  return {
    user,
    loading,
    checkAuth,
    userData,
    updateUserData,
    fetchUserData,
    setUserData,
    userMeta,
    loadingUserMeta,
    checkUser,
    loadingCheckUser,
    token,
    logout,
    checkAvailable,
    handleVerify,
    renewToken,
  };
};

export default useAuth;
