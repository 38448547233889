import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { checkLanguage } from 'assemblr-ui';
import AppServices from '../../services/AppService';
import UserServices from '../../services/UserServices';
import userAgent from '../../utils/userAgent';

const inititalState = {
  listAppLoading: false,
  listAppByKeyword: null,
  listAppError: null,
  showAccountSetting: false,
  currency: {
    code: checkLanguage(['id', 'id-ID']) ? 'IDR' : 'USD',
    hasSet: true,
  },
  iframePopup: { open: false, url: '', isSticky: false },
  enableAds: false,
  quota: null
};

export const asyncGetListApp = createAsyncThunk(
  'app/getListApp',
  async ({
    keys = [
      'explore_content_bg',
      'login_banner_img',
      'onboarding_skip_utm',
      'profile-completion',
      'creator_dashboard_submission_agreement',
      'verification_exclude_list',
      'profile-completion',
    ],
    startWith = null,
  } = {}) => {
    const listApp = await AppServices.getListApp({ keys, startWith });
    return { listApp, startWith };
  },
);

export const appFetchKeys = {
  asyncGetListApp: 'asyncGetListApp',
};

export const asyncPutCurrency = createAsyncThunk(
  'app/putCurrency',
  async (currencyCode) => {
    if (window.LastSetCurrency === currencyCode) {
      throw new Error('Has set currency');
    }
    window.LastSetCurrency = currencyCode;
    const currency = await UserServices.putCurrency(currencyCode);
    console.log({ ___updateCurrency: currency });
    return currency;
  },
);

export const appSlice = createSlice({
  name: 'app',
  initialState: inititalState,
  reducers: {
    resetListApp: (state) => {
      state.listAppLoading = false;
      state.listAppByKeyword = {};
      state.listAppError = null;
      state.iframePopup = { open: false, url: '' };
    },
    setIframePopup: (state, { payload: { open = false, url = '', isSticky = false } }) => {
      state.iframePopup = { open, url, isSticky };
    },
    setShowAccountSetting: (state, { payload = false }) => {
      state.showAccountSetting = payload;
    },
    setCurrency: (state, { payload }) => {
      if (payload === 'IDR' || payload === 'USD') {
        state.currency = {
          code: payload,
          hasSet: true,
        };
      }
      if (typeof payload === 'object') {
        state.currency = payload;
      }
    },
    setEnableAds: (state, { payload }) => {
      state.enableAds = payload;
    },
    setQuota: (state, { payload }) => {
      state.quota = payload;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(asyncGetListApp.pending, (state) => {
      state.listAppLoading = true;
    });
    addCase(asyncGetListApp.fulfilled, (state, { payload }) => {
      if (typeof payload.listApp === 'object') {
        const allowedKeys = [
          // MANDATORY CONFIG
          'explore_content_bg',
          'login_banner_img',
          'onboarding_skip_utm',
          'profile-completion',
          'creator_dashboard_submission_agreement',
          'grace_period_monthly',
          'verification_exclude_list',
          'profile-completion',

          // ENABLE IF TEMPLATE ALREADY SUPPORTED
          // 'featured_content_details',
          // 'featured_content_bg',
          // 'featured_content_bg_mobile',
          'promo_popup',
          'promo_banner',
          'ads',
        ];

        const newKeywords = payload?.listApp?.reduce?.((acc, app) => {
          const { key, ...rest } = app;
          if (allowedKeys.some((allowedKey) => key.includes(allowedKey))) {
            acc[key] = rest;
          }
          return acc;
        }, {}) || {};

        const oldKeywordsWithoutPromo = Object.keys(state.listAppByKeyword || {})
          .filter(
            (key) => !key.includes('promo_banner') && !key.includes('promo_popup'),
          )
          .filter((key) => (payload.startWith ? key.includes(payload.startWith) : true))
          .reduce((acc, key) => {
            acc[key] = state.listAppByKeyword[key];
            return acc;
          }, {});

        state.listAppByKeyword = {
          ...oldKeywordsWithoutPromo,
          ...newKeywords,
        };
      }
      state.listAppLoading = false;
    });
    addCase(asyncGetListApp.rejected, (state, { payload }) => {
      state.listAppError = payload;
      state.listAppLoading = false;
    });
    addCase(asyncPutCurrency.fulfilled, (state, { payload }) => {
      if (userAgent.isMobile.Assemblr()) {
        state.currency = {
          code: 'USD',
          hasSet: true,
        };
      } else {
        state.currency = payload;
      }
    });
  },
});

export const {
  resetListApp,
  setShowAccountSetting,
  setCurrency,
  setIframePopup,
  setEnableAds,
  setQuota,
} = appSlice.actions;

export const appState = (state) => state.app;

const appReducer = appSlice.reducer;

export default appReducer;
