import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuth, getIdToken } from 'firebase/auth';
import API_URL from '../../config/API_URL';
import apiService from '../../services/apiService';

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: async (headers) => {
    let token = localStorage.getItem('_token');
    if (token) {
      headers.set('Authorization', token);
    }
    const { currentUser } = getAuth();
    if (currentUser) {
      try {
        token = await getIdToken(currentUser);
        if (token) {
          headers.set('Authorization', token);
        }
      } catch (e) {
        console.log(e);
      }
    }

    return headers;
  },
});

export default baseQuery;

export const axiosBaseQuery = async ({
  url, method, data, params, headers,
}) => {
  console.log('url', url, 'method', method, 'data', data, 'params', params, 'headers', headers);
  try {
    const result = await apiService({
      url,
      method,
      data,
      params,
      headers,
    });
    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};
