import {
  DeviceHasReachedLimit, DevicesLoginLimitation, LoginDeviceLimitation, ThemeWrapper,
} from 'assemblr-ui';
import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { useRouter } from 'next/router';
import useAuth from '../../../hooks/useAuth';
import AuthenticationServices from '../../../services/AuthenticationServices';

export default function DeviceLoginLimitaton() {
  const { logout } = useAuth();

  const [deviceHasReachedLimit, setDeviceHasReachedLimit] = useState(false);
  const [otherDevices, setOtherDevices] = useState([]);
  const [removedDevice, setRemovedDevice] = useState(null);
  const { replace } = useRouter();

  const auth = getAuth();

  const getOtherDevices = async () => {
    try {
      const currentDevice = await DevicesLoginLimitation.getCurrentDevice();
      const devices = await DevicesLoginLimitation.getAllDevices();
      setOtherDevices(devices?.filter((device) => device.deviceId !== currentDevice?.deviceId) || []);
    } catch (e) {
      console.log('getOtherDevices error', e);
    }
  };

  const handleAcceptLoggedOut = async (currentDeviceLoggedOutKey) => {
    logout();
    await DevicesLoginLimitation.clearRemovedDevice(currentDeviceLoggedOutKey);
    setRemovedDevice(null);
    setDeviceHasReachedLimit(false);
    await DevicesLoginLimitation.removeUser();
    replace('/explore');
    return Promise.resolve();
  };

  useEffect(() => {
    const subscribe = auth.onAuthStateChanged(async (user) => {
      console.log('device.auth.onAuthStateChanged', user);
      if (!user) {
        setDeviceHasReachedLimit(false);
        setOtherDevices([]);
        setRemovedDevice(null);
        return;
      }
      const checkUser = await AuthenticationServices().checkUser();
      const exclude = ['Admin', 'Internal Contributor'];
      if (user?.accessToken && !exclude.includes(checkUser?.type_account)) {
        DevicesLoginLimitation.setUserWithToken(user.accessToken)
          .then(async () => {
            console.log('DevicesLoginLimitation.setUserWithToken success');
            DevicesLoginLimitation.isCurrentDeviceRemoved()
              .then((isRemoved) => {
                console.log('DevicesLoginLimitation.isCurrentDeviceRemoved', isRemoved);
                if (isRemoved) {
                  if (isRemoved?.hasShowRemovedDevice) {
                    handleAcceptLoggedOut(isRemoved?.deviceId);
                    return;
                  }
                  setRemovedDevice(isRemoved);
                } else {
                  // cek apakah device yg lagi dipake udah melebihi limit
                  DevicesLoginLimitation
                    .saveDeviceInfo()
                    .catch(async (e) => {
                      console.log('DevicesLoginLimitation.saveDeviceInfo error', e);
                      await getOtherDevices();
                      setDeviceHasReachedLimit(true);
                    });
                  // listen juga kalau ada yg ngeluarin device
                  DevicesLoginLimitation.listenDeviceRemoved(async () => {
                    await getOtherDevices();
                    DevicesLoginLimitation.isCurrentDeviceRemoved()
                      .then((isRemovedDevice) => {
                        if (isRemovedDevice) {
                          setRemovedDevice(isRemovedDevice);
                        }
                      });
                  });
                }
              });
          });
      }
    });

    return () => {
      subscribe();
    };
  }, []);

  const handleLogin = async () => {
    DevicesLoginLimitation.saveDeviceInfo()
      .then(() => setDeviceHasReachedLimit(false))
      .catch(() => getOtherDevices())
      .finally(() => Promise.resolve());
  };

  const handleRemoveDevice = async (device) => {
    await DevicesLoginLimitation.removeDevice(device.deviceId);
    setOtherDevices(otherDevices.filter((otherDevice) => otherDevice.deviceId !== device.deviceId));
    return Promise.resolve();
  };

  useEffect(() => {
    if (deviceHasReachedLimit || removedDevice) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [deviceHasReachedLimit, removedDevice]);

  return (
    <ThemeWrapper>
      <LoginDeviceLimitation
        devices={otherDevices}
        removeDevice={handleRemoveDevice}
        onLogin={handleLogin}
        visible={deviceHasReachedLimit}
        maxDevices={DevicesLoginLimitation.getDeviceLimitation()}
      />
      <DeviceHasReachedLimit
        visible={!!removedDevice}
        onAccept={handleAcceptLoggedOut}
        device={removedDevice}
        maxDevices={DevicesLoginLimitation.getDeviceLimitation()}
      />
    </ThemeWrapper>
  );
}
