/* eslint-disable func-names */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-invalid-html-attribute */
/* eslint-disable react/react-in-jsx-scope */
import 'survey-core/defaultV2.min.css';
import '../styles/globals.scss';
import 'antd/dist/reset.css';
import 'assemblr-ui/dist/index.css';
import 'assemblr-lp/dist/index.css';
import { Provider, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { getAuth, getIdToken } from 'firebase/auth';
import Head from 'next/head';
import { PersistGate } from 'redux-persist/integration/react';
// import { notification } from 'antd';
import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import Script from 'next/script';
import useInitialActions from '../hooks/useInitialActions';
import ErrorBoundary from '../components/miscellaneous/error_boundary';
import { injectStoreToApiRequest } from '../config/API_Request';
import Seo from '../components/miscellaneous/seo';
import { wrapper, persistor } from '../store/store';
import useCustomPosthog from '../hooks/useCustomPosthog';
import PopupBannerWithPosthog, { UnpaidInvoicePopUp } from '../components/miscellaneous/popup_banner';
import {
  //  asyncGetInitialRenewalInvoiceNotifications,
  asyncGetInitialUnpaidInvoiceNotifications,
} from '../store/reducers/notificationReducer';
import userAgent from '../utils/userAgent';
import useNotificationWorker from '../hooks/useNotificationWorker';
import useTallyWidget from '../hooks/useTallyWidget';
import NewRelic from '../config/NewRelic';
import DeviceLoginLimitaton from '../components/miscellaneous/device_login_limitation';
import MainLoader from '../components/miscellaneous/loader/main';
import { HistoryProvider, TrackHistory } from '../hooks/useHistory';
import onAuthStateChange from '../utils/onAuthStateChange';
import useAuth from '../hooks/useAuth';
import useAnalytics from '../hooks/useAnalytics';
import { playerUrl } from '../config/getdomain';
import useFirebase from '../hooks/useFirebase';
import { setUnityVersion } from '../store/reducers/helperReducer';
import IframePopup from '../components/miscellaneous/iframe_popup';

const posthogKey = ((process.env.NODE_ENV === 'production') && !(process.env.NEXT_PUBLIC_WEB_URL).includes('pr-')) ? process.env.NEXT_PUBLIC_POSTHOG_KEY : process.env.NEXT_PUBLIC_POSTHOG_KEY_DEV;

if (typeof window !== 'undefined') {
  posthog.init(posthogKey, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    loaded: (ph) => {
      if (process.env.NODE_ENV === 'development') ph.debug();
    },
    autocapture: false,
    capture_pageview: false,
    capture_pageleave: false,
    persistence: 'localStorage+cookie',
    disable_session_recording: true,
  });
}

function MyApp({ Component, ...rest }) {
  const { store, props: { pageProps, router: pageRouter } } = wrapper.useWrappedStore(rest);
  const Layout = Component.getLayout || ((page) => page);
  const auth = getAuth();

  const getCurrentUID = async () => {
    const UUID = localStorage.getItem('UUID');
    console.log(UUID, 'UUID');
    if (UUID) {
      return UUID;
    }
    return 'Guest';
  };

  const getFirebaseToken = async () => {
    if (auth && auth.currentUser) {
      const token = await getIdToken(auth.currentUser);
      return token;
    }
    return null;
  };

  const getCustomToken = async () => {
    if (auth && auth.currentUser) {
      const token = await fetch(`https://us-central1-assemblr-1ff3f.cloudfunctions.net/app/v2/token/${auth.currentUser.uid}`, {
        method: 'GET',
      });
      const { results } = await token.json();
      return results;
    }
    return null;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const button2 = document.createElement('button');
    button2.style.position = 'fixed';
    button2.style.top = 0;
    button2.value = '';
    button2.id = 'get-token';
    button2.style.display = 'none';
    button2.onclick = async function () {
      const token = await getCustomToken();
      button2.value = token;
    };

    const button3 = document.createElement('button');
    button3.style.position = 'fixed';
    button3.style.top = 0;
    button3.value = '';
    button3.id = 'get-uid';
    button3.style.display = 'none';
    button3.onclick = async function () {
      const uid = await getCurrentUID();
      const UUID = localStorage.getItem('UUID');
      console.log(UUID, 'UUID');
      console.log(uid, 'UUID');
      button3.value = uid;
    };

    const button4 = document.createElement('button');
    button4.style.position = 'fixed';
    button4.style.top = 0;
    button4.value = '';
    button4.id = 'floating-button';
    button4.style.display = 'none';
    button4.onclick = async function () {
      const token = await getFirebaseToken();
      button4.value = token;
    };

    const button5 = document.createElement('button');
    button5.style.position = 'fixed';
    button5.style.top = 0;
    button5.value = '';
    button5.id = 'unity-version';
    button5.style.display = 'none';
    button5.onclick = async function () {
      if (document.getElementById('3d-library')) {
        if (!document.getElementById('3d-library')?.src?.includes('unity-version')) {
          document.getElementById('3d-library').src = `${document.getElementById('3d-library').src}&unity-version=${button5.value}`;
        }
      }
      if (button5.value && button5.value !== '') {
        dispatch(setUnityVersion(button5.value));
      }
    };

    const button6 = document.createElement('button');
    button6.style.position = 'fixed';
    button6.style.top = 0;
    button6.value = '';
    button6.id = 'show-after-purchase';
    button6.style.display = 'none';
    button6.onclick = function () {
      console.log('testing blr showing');
      const iframe3D = document.getElementById('3d-library')?.contentWindow;
      iframe3D?.postMessage('show-after-purchase', '*');
      setTimeout(() => {
        window.postMessage('show blr');
        console.log('testing blr showing');
      }, 1000);
    };

    document.body.appendChild(button2);
    document.body.appendChild(button3);
    document.body.appendChild(button4);
    document.body.appendChild(button5);
    document.body.appendChild(button6);
  }, []);

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-NBVNMD8' });
  }, []);

  useInitialActions();

  injectStoreToApiRequest(store);
  useCustomPosthog();
  useNotificationWorker();
  useTallyWidget();
  useFirebase();
  onAuthStateChange();
  useAnalytics(true);

  useEffect(() => {
    if (auth && auth.currentUser) {
      dispatch(asyncGetInitialUnpaidInvoiceNotifications());
      // dispatch(asyncGetInitialRenewalInvoiceNotifications()); DISABLE RENEWAL NOTIF
    }
  }, [auth, auth.currentUser]);

  // const [notificationApi, notificationContextHolder] = notification.useNotification();
  // const { notifications, removeNotification } = useFirebase();
  const {
    watermark, hideCloseBtn, transparent, fullscreenBtn,
  } = pageRouter.query;
  const embedParam = new URLSearchParams({
    oembed: true,
    watermark: (watermark === 'true'),
    hideCloseBtn: (hideCloseBtn === 'true'),
    ...(transparent === 'true' || !transparent) && { transparent: true },
    noFullScreen: (fullscreenBtn === 'false' || !fullscreenBtn),
  });
  const embedUrl = pageProps?.pblr
    ? `${playerUrl()}/Embed/${pageProps.pblr}?${embedParam.toString()}`
    : pageRouter.asPath;
  const oembedUrl = `${process.env.NEXT_PUBLIC_WEB_URL}/api/oembed?url=${encodeURIComponent(embedUrl)}`;

  // useEffect(() => {
  //   notifications.forEach((notif) => {
  //     notificationApi.open({
  //       key: notif.id,
  //       message: notif.title,
  //       description: notif.image ? (
  //         <div className="notification-image">
  //           <img src={notif.image} alt="Notification" />
  //           <div dangerouslySetInnerHTML={{ __html: notif.body }} suppressHydrationWarning />
  //         </div>
  //       ) : (<div dangerouslySetInnerHTML={{ __html: notif.body }} suppressHydrationWarning />),
  //       className: 'firebase-notification',
  //       duration: 60,
  //       onClose: () => removeNotification(notif.id),
  //       onClick: () => {
  //         if (notif.target_url) {
  //           window.open(notif.target_url, '_blank');
  //         }
  //         removeNotification(notif.id);
  //       },
  //     });
  //   });
  // }, [notificationApi, notifications]);

  useEffect(() => {
    //buat solve notif browser outdate yg cuma muncul sekali pas buka page
    if (!userAgent.isMobile.Assemblr()) {
      document.cookie = 'browserupdateorg=; Max-Age=0';
    }
  }, [pageRouter.pathname]);

  useAuth(true);

  return (
    <ErrorBoundary>
      {Layout(
        <>
          {/* {notificationContextHolder} */}
          {!userAgent.isMobile.Assemblr() && auth?.currentUser
            && (
              <>
                <PopupBannerWithPosthog />
                <UnpaidInvoicePopUp />
              </>
            )}
          <Head>
            <Script type="text/javascript" src={NewRelic.script} />
            {pageProps?.pblr && (
              <>
                <link
                  rel="iframely app 3d"
                  href={embedUrl}
                  media="aspect-ratio: 2000/2000; max-width: 3840;"
                />
                <link
                  rel="alternate"
                  type="application/json+oembed"
                  href={oembedUrl}
                  title={pageProps?.seo?.title}
                />
                <meta
                  name="iframely:attach"
                  content={embedUrl}
                />
                <meta property="iframely:medium" content="rich" />
              </>
            )}
          </Head>
          <Seo {...pageProps.seo} />
          <PostHogProvider client={posthog}>
            <PersistGate loading={null} persistor={persistor}>
              <DeviceLoginLimitaton />
              <MainLoader />
              <HistoryProvider>
                <TrackHistory />
                <Component {...pageProps} />
                <IframePopup />
              </HistoryProvider>
            </PersistGate>
          </PostHogProvider>
        </>,
      )}
    </ErrorBoundary>
  );
}

function AppWrapper({ Component, ...rest }) {
  const { store, props } = wrapper.useWrappedStore(rest);

  return (
    <Provider
      store={store}
    >
      <MyApp Component={Component} {...props} />
    </Provider>
  );
}

export default AppWrapper;
